import 'antd/dist/antd.css';
import { Layout, Menu } from 'antd';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import Home from './views/Home';
import Faq from './views/Faq';
import logoSvg from './logo.svg';

export default function App() {
  const location = useLocation();

  return (
    <Layout>
      <div
        style={{
          position: 'fixed',
          textAlign: 'left',
          alignItems: 'center',
          left: 0,
          top: 0,
          padding: 10,
          fontSize: 24,
          fontWeight: 'bold',
        }}
      >
        <img height={42} src='/logo.png' alt='NFT Replica' />
        &nbsp;
        <a style={{ color: '#ffffff' }} href='/' alt='home'>
          NFT Replicas
        </a>
      </div>

      <Menu style={{ fontSize: 16, textAlign: 'center', fontWeight: 'bold', lineHeight: '54px' }} selectedKeys={[location.pathname]} mode='horizontal'>
        <Menu.Item key='/'>
          <Link to='/'>Home</Link>
        </Menu.Item>
        <Menu.Item key='/replicate'>
          <Link to='/replicate'>Replicate NFT</Link>
        </Menu.Item>
        <Menu.Item key='/faq'>
          <Link to='/faq'>FAQ</Link>
        </Menu.Item>
      </Menu>

      <Layout.Content>
        <br />

        <div style={{ maxWidth: 700, margin: 'auto' }}>
          <Switch>
            <Route exact path='/faq'>
              <Faq />
            </Route>
            <Route>
              <Home />
            </Route>
          </Switch>
        </div>
      </Layout.Content>

      <Layout.Footer style={{ textAlign: 'center' }}>
        NFT Replicas &copy;{(new Date()).getYear() + 1900}
      </Layout.Footer>
    </Layout>
  );
}
