import { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import NftViewer from '../components/NftViewer';
import _ from 'lodash';
import qs from 'qs';

const galleryData = require('./gallery-data.json');

function getRandomNft(nftCollection) {
  if (!nftCollection) {
    nftCollection = _.sample(galleryData);
  }

  return {
    name: nftCollection.name,
    address: nftCollection.address,
    id: _.sample(nftCollection.ids),
  };
}

function Nft({ address, id, name }) {
  const [error, setError] = useState();

  function handleError(error) {
    setError(error);
  }

  if (error) {
    return null;
  }

  return (
    <Col span={8}>
      <Card
        actions={[
          <Button target='_blank' href={`https://opensea.io/assets/ethereum/${address}/${id}`} type='link' size='small'>
            OpenSea
          </Button>,
          <Link to={`/replicate?${qs.stringify({contractAddress: address, tokenId: id})}`}>
            <Button size='small' type='primary'>
              Replicate
            </Button>
          </Link>
        ]}
        cover={<NftViewer onError={handleError} address={address} id={id} showMeta={false} />}>
        <Card.Meta title={name} description={`#${id}`} />
      </Card>
    </Col>
  );
}

export default function Gallery() {
  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    const azukis = _.times(3, () => getRandomNft(galleryData[0]));
    setNfts(azukis.concat(_.times(9, () => getRandomNft())));
  }, []);

  return (
    <div>
      <h1>👋&nbsp;&nbsp;Welcome to NFT Replicas!</h1>
      <p>
        We allow you to make an exact replica of virtually any NFT. Check out
        the <Link to='/faq'>FAQ</Link> for more info.
      </p>
      <p>
        If you know the contract address and token ID of an NFT you want to replicate,
        you can <Link to='/replicate'>replicate it here.</Link>
      </p>
      <br />
      <h2>
        🎨&nbsp;&nbsp;Popular Gallery&nbsp;
        <Button title='Randomize' onClick={() => window.location.reload()}>
          <ReloadOutlined />
        </Button>
      </h2>
      <p>
        Here is a random sampling of popular NFTs that you can replicate.
      </p>

      <Row gutter={[12, 12]}>
        {nfts.map((nft, idx) => (
          <Nft key={idx} {...nft} />
        ))}
      </Row>
    </div>
  );
}