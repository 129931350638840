import { useEffect } from 'react';
import { Alert, Image, Skeleton } from 'antd';
import { useNft } from 'use-nft';

export default function NftViewer({ address, id, showMeta = true, onError }) {
  const { loading, error, nft } = useNft(address, id);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error]);

  if (error) {
    console.error(error);
    return <Alert message='Unable to load NFT' description={error.message} type='error' closable />;
  }

  function renderNft(nft) {
    if (nft.imageType === 'video') {
      return <video src={nft.image} />;
    } else {
      return <Image width='100%' src={nft.image} />;
    }
  }

  return (
    <div>
      <Skeleton active={loading} loading={loading}>
        {nft && (
          <>
            {showMeta && (
              <>
                <h2>{nft.name}</h2>
                <p>{nft.description}</p>
              </>
            )}
            {renderNft(nft)}
          </>
        )}
      </Skeleton>
    </div>
  );
}
