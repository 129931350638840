import { Collapse } from 'antd';

const FAQ = [
  [
    'Why would I want to create a replica?',
    `Many NFTs have 🚀 in price and are not affordable for most people. This allows you to still own a replica of a famous NFT. Think of it like an exact print of a favorite piece of art.
     The source of the NFT will be exactly the same as the original and it will show up in your crypto wallet. You can even set it as your official Twitter Blue profile pic.`,
  ],
  [
    'Can I view the replica NFT in my crypto wallet or on sites like OpenSea?',
    'Yes! NFT Replicas is a fully ERC-1155 compliant contract on the Ethereum blockchain.',
  ],
  [
    'How much does it cost?',
    `The price for a replica starts at 0.008 ETH. Each time a particular NFT piece is replicated, its next replication cost will double. So be sure to replicate popular pieces early!`,
  ],
  ['Can I sell the replica?', 'Yes, they can be listed for sale on sites like OpenSea.'],
  [
    'Where can I see my replica?',
    <span>
      The entire NFT Replicas collection can be viewed at{' '}
      <a href='https://opensea.io/collection/nftreplicas' target='_blank'>
        OpenSea NFT Replicas
      </a>
      .
      <br />
      The Polygon network collection can be viewed at{' '}
      <a href='https://opensea.io/collection/nftreplicas-polygon' target='_blank'>
        OpenSea NFT Replicas (polygon)
      </a>
      .
    </span>,
  ],
  ["Why can't I replicate a replica?", "Do you want black holes? Because that's how you get black holes."],
  [
    'What is the contract address?',
    <span>
      The Ethereum contract is{' '}
      <a href='https://etherscan.io/address/0xf7e842c0aa325c7e83a3f7a9c11d6dd835fe9800' target='_blank'>
        0xf7e842c0aa325c7e83a3f7a9c11d6dd835fe9800
      </a>
      .<br />
      The Polygon contract is{' '}
      <a href='https://polygonscan.com/address/0x7722083eddd22af4bb087a3403054dbaf0e33166' target='_blank'>
        0x7722083eddd22af4bb087a3403054dbaf0e33166
      </a>
      .
    </span>,
  ],
  [
    'What if I have other questions or feedback?',
    <span>
      Feel free to send an email to{' '}
      <a target='_blank' href='mailto:nftreplicas.net@gmail.com'>
        nftreplicas.net@gmail.com
      </a>
    </span>,
  ],
];

export default function Faq() {
  return (
    <Collapse style={{ fontSize: 18 }}>
      {FAQ.map(([q, a], idx) => (
        <Collapse.Panel header={q} key={idx}>
          <p>{a}</p>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}
