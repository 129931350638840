import { utils, BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { Button, Result, Card, Form, Input, Modal, Select, Space, message } from 'antd';
import { get } from 'lodash';
import { AddressInput } from './';
import NftViewer from './NftViewer';
import useQuery from '../hooks/useQuery';
import './Replica.css';

// const SUPPORTED_NETWORKS = [
//   ['localhost', 'Localhost'],
//   ['rinkeby', 'Rinkeby'],
//   ['mumbai', 'Polygon Mumbai'],
// ];

const SUPPORTED_NETWORKS = [
  ['mainnet', 'Ethereum'],
  ['polygon', 'Polygon'],
];

const ADDRESS_TO_OPENSEA = {
  '0xf7e842c0aa325c7e83a3f7a9c11d6dd835fe9800': 'https://opensea.io/collection/nftreplicas',
  '0x7722083eddd22af4bb087a3403054dbaf0e33166': 'https://opensea.io/collection/nftreplicas-polygon',
  '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9': 'https://opensea.io/collection/nftreplicas'
};

export default function Replicator({
  contract,
  signer,
  onNetworkChange,
  defaultNetwork,
  mainnetProvider,
  isWrongNetwork,
}) {
  const query = useQuery();
  const [contractAddress, setContractAddress] = useState(query.contractAddress);
  const [tokenId, setTokenId] = useState(query.tokenId);
  const [recipientAddress, setRecipientAddress] = useState(query.recipientAddress);
  const [viewNft, setViewNft] = useState({});
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [isReplicating, setIsReplicating] = useState(false);
  const [price, setPrice] = useState();
  const [uri, setUri] = useState();
  const [isPurchased, setIsPurchased] = useState(false);

  useEffect(() => {
    async function getAddress() {
      if (signer) {
        const newAddress = await signer.getAddress();
        setRecipientAddress(newAddress);
      }
    }
    getAddress();
  }, [signer]);

  function isViewLoaded() {
    return !isViewLoading && viewNft.contractAddress == contractAddress && viewNft.tokenId == tokenId;
  }

  const handleView = async () => {
    try {
      setIsViewLoading(true);
      setViewNft({
        contractAddress,
        tokenId,
      });

      const uri = await contract['tokenURI(address,uint256)'](
        utils.getAddress(contractAddress),
        BigNumber.from(tokenId),
      );
      setUri(uri);
      if (!uri.startsWith('ipfs') && !uri.startsWith('http')) {
        throw new Error('This NFT will likely not render correctly as it is a specialized NFT');
      }

      const price = await contract.price(utils.getAddress(contractAddress), BigNumber.from(tokenId));
      setPrice(price);
    } catch (err) {
      console.error(err);
      setUri(null);
      setPrice(null);
      message.error(get(err, 'data.message') || get(err, 'message') || 'Unable to load NFT');
    } finally {
      setIsViewLoading(false);
    }
  };

  const handleReplicate = async () => {
    setIsReplicating(true);
    try {
      await contract.replicate(
        utils.getAddress(contractAddress),
        BigNumber.from(tokenId),
        utils.getAddress(recipientAddress),
        {
          value: price,
        },
      );
      setIsPurchased(true);
    } catch (err) {
      console.error(err);
      message.error(get(err, 'data.message') || get(err, 'message') || 'Unable to replicate this NFT');
    } finally {
      setIsReplicating(false);
    }
  };

  const handleClosePurchase = () => {
    setIsPurchased(false);
    setContractAddress(null);
    setTokenId(null);
    setPrice(null);
  };

  function renderPurchased() {
    return (
      <Result
        status='success'
        title='Successfully Purchased Replica!'
        subTitle={
          <span>
            It may take a couple of minutes for the transaction to complete. You can close this message at any time.
            <br />
            In a few minutes, you can see your NFT in the{' '}
            <a href={ADDRESS_TO_OPENSEA[contract.address]} target='_blank'>
              NFT Replicas
            </a>{' '}
            collection.
          </span>
        }
        extra={[
          <Button type='primary' key='console' onClick={handleClosePurchase}>
            Close
          </Button>,
        ]}
      />
    );
  }

  function renderForm() {
    return (
      <>
        <Form name='basic' labelCol={{ span: 5 }} initialValues={{ remember: true }} autoComplete='off'>
          <Form.Item label='Network'>
            <Select defaultValue={defaultNetwork} style={{ width: 120 }} onChange={onNetworkChange}>
              {SUPPORTED_NETWORKS.map(([value, label]) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            required
            tooltip='Contract address of the NFT'
            label='NFT Address'
            rules={[{ required: true, message: "Please specify the NFT's contract address" }]}
          >
            <Input
              type='text'
              onChange={e => setContractAddress(e.target.value)}
              value={contractAddress}
              placeholder='0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d'
            />
          </Form.Item>

          <Form.Item
            label='NFT Token ID'
            required
            tooltip='Token ID of the NFT'
            rules={[{ required: true, message: 'Please specify the token ID' }]}
          >
            <Input type='text' onChange={e => setTokenId(e.target.value)} value={tokenId} placeholder='123' />
          </Form.Item>

          <Form.Item
            label='Recipient'
            required
            tooltip='Address to send NFT to'
            rules={[{ required: true, message: 'Please specify who to send NFT to' }]}
          >
            <AddressInput
              ensProvider={mainnetProvider}
              onChange={value => setRecipientAddress(value)}
              value={recipientAddress}
              placeholder='0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d'
            />
          </Form.Item>
        </Form>

        <Space style={{ display: 'flex', justifyContent: 'end' }}>
          {signer ? (
            <>
              <Button
                loading={isViewLoading}
                disabled={isWrongNetwork || !contractAddress || !tokenId}
                type={isViewLoaded() ? 'default' : 'primary'}
                onClick={handleView}
              >
                {isWrongNetwork ? `Please change your wallet's network to "${defaultNetwork}"` : '1. View'}
              </Button>
              {!isWrongNetwork && (
                <Button
                  loading={isReplicating}
                  disabled={!isViewLoaded() || !uri || !price}
                  type='primary'
                  onClick={handleReplicate}
                >
                  2. Replicate
                </Button>
              )}
            </>
          ) : (
            <Button disabled>Please Connect your wallet first</Button>
          )}
        </Space>
      </>
    );
  }

  return (
    <Card style={{ overflow: 'hidden' }} cover={<div className='header'>Replicate an NFT</div>}>
      {isPurchased ? renderPurchased() : renderForm()}
      {isViewLoaded() && price && (
        <>
          <div style={{ textAlign: 'right', fontSize: 16 }}>
            <br />
            Price:&nbsp;
            <b>{price && utils.formatEther(price)}&nbsp;ETH</b>
          </div>
          <NftViewer address={contractAddress} id={tokenId} />
        </>
      )}
    </Card>
  );
}
