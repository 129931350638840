import { NftProvider, useNft } from 'use-nft';
import { useUserProviderAndSigner } from 'eth-hooks';
import { useEventListener } from '../hooks/useEventListener';
import { Contract, getDefaultProvider } from 'ethers';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Alert, Button, Row, Col, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useStaticJsonRPC } from '../hooks';
import { NETWORKS, ALCHEMY_KEY } from '../constants';
import { Web3ModalSetup } from '../helpers';
import Replicator from '../components/Replicator';
import { Address, Faucet } from '../components';
import deployedContracts from '../contracts/hardhat_contracts.json';
import NftViewer from '../components/NftViewer';
import useQuery from '../hooks/useQuery';
import Gallery from './Gallery';

const { ethers } = require('ethers');

const web3Modal = Web3ModalSetup();

const providers = [
  'https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406',
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  'https://rpc.scaffoldeth.io:48544',
];

const DEFAULT_NETWORK = 'mainnet';

export default function App() {
  const query = useQuery();
  const [isWrongNetwork, setIsWrongNetwork] = useState();
  const [targetNetwork, setTargetNetwork] = useState(NETWORKS[query.network || DEFAULT_NETWORK]);
  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  // const localProvider = useStaticJsonRPC([
  //   process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  // ]);

  const mainnetProvider = useStaticJsonRPC(providers);

  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider); //, localProvider);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function check() {
      if (!injectedProvider || !targetNetwork) {
        return;
      }

      setIsWrongNetwork((await injectedProvider.getNetwork()).chainId !== targetNetwork.chainId);
    }

    check();
  }, [injectedProvider, targetNetwork]);

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == 'function') {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on('chainChanged', chainId => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on('accountsChanged', () => {
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on('disconnect', (code, reason) => {
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const handleNetworkChange = value => {
    setTargetNetwork(NETWORKS[value]);
  };

  const contractInfo = deployedContracts[targetNetwork.chainId][targetNetwork.name].contracts['Replicator'];
  const contract = useMemo(() => {
    return new Contract(contractInfo.address, contractInfo.abi, userSigner);
  }, [contractInfo, userSigner]);

  // const contractConfig = {
  //   deployedContracts: deployedContracts || {},
  // };

  // const localChainId = localProvider && localProvider._network && localProvider._network.chainId;

  return (
    <NftProvider
      fetcher={[
        'ethers',
        {
          provider:
            injectedProvider || getDefaultProvider('https://mainnet.infura.io/v3/127d32004edc4edcad3407d7f95d77c5'),
        },
      ]}
      ipfsUrl={(cid, path = '') => `https://ipfs.io/ipfs/${cid}${path}`}
    >
      <Row justify='space-around' align='middle'>
        <div style={{ position: 'fixed', textAlign: 'right', right: 0, top: 0, padding: 10 }}>
          <Space>
            {address && <Address fontSize={18} address={address} ensProvider={mainnetProvider} />}
            {address ? (
              <Button title='Disconnect' type='dashed' onClick={logoutOfWeb3Modal}>
                <LogoutOutlined />
              </Button>
            ) : (
              <Button type='primary' onClick={loadWeb3Modal}>
                Connect
              </Button>
            )}
          </Space>
        </div>
        <Col span={24}>
          {isWrongNetwork && (
            <Alert
              message="Change Your Wallet's Network"
              description={`Please select the '${targetNetwork.name}' network in your wallet`}
              type='warning'
              showIcon
            />
          )}
          <br />
          <Switch>
            <Route exact path='/replicate'>
              <Replicator
                contract={contract}
                signer={userSigner}
                onNetworkChange={handleNetworkChange}
                defaultNetwork={targetNetwork.name}
                mainnetProvider={mainnetProvider}
                isWrongNetwork={isWrongNetwork}
              />
            </Route>
            <Route>
              <Gallery />
            </Route>
          </Switch>
        </Col>

        {/* {injectedProvider && userSigner && (
          <NftProvider fetcher={['ethers', {provider: injectedProvider}]}>
            <RecentReplicas contract={contract} network={targetNetwork.name} />
          </NftProvider>
        )} */}

        {/* <Faucet localProvider={localProvider} /> */}
      </Row>
    </NftProvider>
  );
}

const RecentReplicas = React.memo(function RecentReplicas({ contract, network }) {
  const results = useEventListener(contract, 'Replicate', 1);

  return (
    <div>
      {results.map((r, i) => (
        <Replica
          key={i}
          contractAddress={r.args.srcContractAddress}
          tokenId={r.args.srcTokenId.toNumber()}
          network={network}
        />
      ))}
    </div>
  );
});

const Replica = React.memo(function Replica({ network, contractAddress, tokenId }) {
  const { loading, error, nft } = useNft(contractAddress, tokenId);

  if (loading || error) {
    return null;
  }

  return <NftViewer uri={nft.metadataUrl} />;
});
